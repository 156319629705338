import React from 'react';
import styled from 'styled-components';
import {Link, graphql} from 'gatsby';
import H2 from './../components/typo/H2';
import H3 from './../components/typo/H3';
import P from './../components/typo/P';
import Layout from './../components/Layout';

const Page = styled.section`
  border-top: 1px solid #e20074;
  //padding-top: 80px;
`;
const C = P.withComponent('cite');
const Cite = styled(C)`
  color: #e20074;
  text-transform: uppercase;
  padding-left: 0 !important;
`;

const Q = P.withComponent('q');
const Quote = styled(Q)`
  padding-left: 0 !important;
`;

const variations = ['b-l', 't-l', 't-r', 'b-r'];

function SocialMedia({data, location}) {
  if (!data) {
    return (
      <Page className="main">
        <section className="news">
          <div className="container">
            <p>Loading....</p>
          </div>
        </section>
      </Page>
    );
  }
  const posts = data.social.edges;

  return (
    <Layout location={location}>
      <Page className="main">
        <section id="social-media" className="social-block pink-style">
          <div className="container">
            <h2>All SOCIAL MEDIA POSTS</h2>
            <ul className="social-media-list">
              {posts.map(({node: p}, k) => (
                <li
                  key={k}
                  className={`arrow arrow-${
                    variations[Math.floor(Math.random() * variations.length)]
                  }`}>
                  <a href={p.url} target="_blank">
                    <span className="icon">
                      <svg className="icon icon-linkedin">
                        <use xlinkHref={`#icon-${p.source}`} />
                      </svg>
                    </span>
                    <blockquote>
                      <Cite>{p.headline}</Cite>
                      <Quote>{p.copy}</Quote>
                    </blockquote>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </section>
      </Page>
    </Layout>
  );
}

export default SocialMedia;

export const query = graphql`
  query SocialPageQuery {
    social: allDatoCmsSocialMediaPost(sort: {fields: [position]}) {
      edges {
        node {
          headline
          url
          source
          copy
        }
      }
    }
  }
`;
